
  import * as userEditorScriptEntry from '/home/builduser/work/7a79e30a3f637800/packages/payment-methods-banner-ooi/src/editor.app.ts';
  const editorScriptEntry = userEditorScriptEntry;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  

    var createExperiments = null;
    var createWidgetExperiments = null;
    

  
import { createHttpClient } from '@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp';
    


  import { createOwnerBILogger as biLogger } from '/home/builduser/work/7a79e30a3f637800/packages/payment-methods-banner-ooi/target/generated/bi/createBILogger.ts';

  
  import { editorReadyWrapper, onEventWrapper, createFlowAPIFabric, exportsWrapper, getAppManifestWrapper } from '@wix/yoshi-flow-editor/runtime/esm/editorScript.js';

  let editorReady = editorScriptEntry.editorReady;
  const onEvent = onEventWrapper({ onEvent: editorScriptEntry.onEvent });
  const sentryConfig = {
    DSN: 'https://3d2f0188674f41fb8de8550ccb4b08e5@sentry.wixpress.com/2306',
    id: '3d2f0188674f41fb8de8550ccb4b08e5',
    projectName: 'payment-methods-banner-ooi',
    teamName: 'payments',
    errorMonitor: true,
  };
  const experimentsConfig = {"scopes":["payments-checkout"],"centralized":true};
  const translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/7a79e30a3f637800/packages/payment-methods-banner-ooi/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};
  const defaultTranslations = {"settings.title":"Installment Payment Options","settings.tabs.design":"Design","settings.design.fontSettings":"Text font & color","settings.design.textAlignmentTitle":"Alignment","settings.design.logoThemeTitle":"Logo appearance","settings.design.logoTheme.dark":"Dark","settings.design.logoTheme.light":"Light","demoView.title":"or {installmentCount} interest-free payments of {currency}{amount} with"};
  const createFlowAPI = createFlowAPIFabric({
    sentryConfig,
    experimentsConfig,
    translationsConfig,
    defaultTranslations,
    biLogger,
    shouldUseEssentials: true,
    artifactId: 'payment-methods-banner-ooi',
    appDefId: 'df892fe9-626f-44c9-a328-e29f93880b38',
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: 'assets/locales',
  });

  const useAPIsFromExports = typeof editorScriptEntry.exports === "function";
  if (editorReady) {
    editorReady = editorReadyWrapper({
      editorReady,
      createFlowAPI,
      shouldSkipAPIOverrides: useAPIsFromExports,
    });
    if (false) {
      const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
      editorReady = hot(module, editorReady)
    }
  }
  export { editorReady };
  export { onEvent };
  export const exports = useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports;
  export const getAppManifest = editorScriptEntry.getAppManifest ? getAppManifestWrapper(editorScriptEntry.getAppManifest, createFlowAPI) : undefined;
  export const handleAction = editorScriptEntry.handleAction;
  
